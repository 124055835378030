import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProgramCamperInfoTabSetting() {
  // Use toast
  const toast = useToast()

  const saveCamperTabSettings = queryParams => axios
    .post('/auth/camper-tab-settings/save', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Program Camper Tab Setting was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error saving program Camper Tab Setting',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    saveCamperTabSettings,
  }
}
