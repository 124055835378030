<template>
  <div>
    <div class="d-flex justify-content-between align-items-center header-height mb-1">
      <template
        v-if="setProgram"
      >
        <div>
          <h3>{{ setProgram.name }}</h3>
          <h4>ID: {{ setProgram.id }}</h4>
        </div>
        <!-- Actions -->
        <b-dropdown
          id="dropdown-buttons"
          text="Actions"
          variant="outline-primary"
          right
        >
          <b-dropdown-item
            :to="{
              name: 'admin-report-details',
              query: {
                report_program_id: programID,
              }
            }"
          >
            <span>View Application Report</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="cloneAndSelectProgram(programID)"
          >
            <span>Clone Program</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="canDeleteProgram"
            @click="deleteAndSelectDefaultProgram(programID)"
          >
            <span>Delete Program</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="expCampers()"
          >
            <span>Export {{ camperStudent(2) }}</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="expStaff()"
          >
            <span>Export Staff</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.bulk-download-files
          >
            <span>Bulk Download File</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.generate-badges
          >
            <span>Generate Badges</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.program-application-sharing-modal
          >
            <span>Share Application</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </div>
    <b-card no-body>
      <b-tabs
        v-model="tabIndex"
        content-class="col-12 mt-1 mt-md-0"
        pills
        fill
        nav-wrapper-class="col-12 py-1 px-0 border-bottom  bg-white"
        nav-class="mb-0 bg-light text-center"
        :disabled="isLoading"
        @activate-tab="tabActivated"
      >

        <b-tab
          active
          :disabled="!canUpdateGeneralProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">General</span>
          </template>
          <ProgramTabMain
            :user-data="options.main"
            :payment-plan="options.paymentPlan"
            :payment-contract="paymentContract"
            :payment-contracts-list="paymentContractsList"
            @nextTab="saveChanges"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateGeneralProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">{{ sessionSemester(2) }}</span>
          </template>
          <ProgramTabSessions
            :sessions="options.sessions"
            :enable-dates="options.main.date"
            :program-data="options.main"
            @nextTab="updateProgram"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateGeneralProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">Communications</span>
          </template>
          <ProgramTabCommunication
            :program-id="programID"
            :user-data="options.communication"
            :communication-settings-list="options.communicationSettingsList"
            @createProgram="saveChanges"
          />
        </b-tab>

        <b-tab
          lazy
          :disabled="!canUpdateRegistrationChildProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">Registration Statuses ({{ camperStudent(2) }})</span>
          </template>

          <ProgramTabRegistrationStatuses
            form-type="Camper"
          />
        </b-tab>

        <b-tab
          lazy
          :disabled="!canUpdateRegistrationStaffProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">Registration Statuses (Staff)</span>
          </template>

          <ProgramTabRegistrationStatuses
            form-type="Staff"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateProgramTasks"
        >
          <template #title>
            <span class="font-weight-bold">Tasks</span>
          </template>
          <ProgramTabTasks
            v-if="programID"
            :program-id="programID"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateProgramFees"
        >
          <template #title>
            <span class="font-weight-bold">Fees</span>
          </template>
          <ProgramTabFees
            v-if="programID"
            :program-id="programID"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateProgramWallets"
        >
          <template #title>
            <span class="font-weight-bold">Wallets</span>
          </template>
          <ProgramTabWallets
            v-if="programID"
            :program-id="programID"
          />
        </b-tab>

        <b-tab
          :disabled="!canUpdateProgramTags"
        >
          <template #title>
            <span class="font-weight-bold">Tags</span>
          </template>
          <ProgramTabTags />
        </b-tab>

        <b-tab
          v-if="isSchoolModuleActive('Family')"
          :disabled="!canUpdateGeneralProgramSettings"
        >
          <template #title>
            <span class="font-weight-bold">Family Settings</span>
          </template>
          <ProgramTabFamilySettings
            v-if="!isLoading"
            :family-settings="options.familySettings"
          />
        </b-tab>

        <b-tab
          lazy
          :disabled="!canUpdateProgramTags"
        >
          <template #title>
            <span class="font-weight-bold">{{ camperStudent(1) }} Info tab</span>
          </template>
          <ProgramTabCamperInfoTab
            v-if="programID"
            :camper-tab-info-settings="options.camperTabInfoSettings"
          />
        </b-tab>

      </b-tabs>
    </b-card>
    <!-- Export Campers -->
    <b-modal
      v-if="setProgram"
      id="export-campers"
      ref="export-campers"
      ok-title="Save"
      cancel-variant="outline-secondary"
      centered
      :title="`Export ${camperStudent(2)}`"
      no-close-on-backdrop
      @ok="saveCSV(camperStudent(2))"
      @hidden="csvFile = null"
    >
      <div
        v-if="isLoading"
        class="text-center my-1"
      >
        <b-spinner class="align-middle" />
      </div>
      <span v-else>{{ csvName(camperStudent(2)) }}.csv</span>
    </b-modal>

    <!-- Export Staff -->
    <b-modal
      v-if="setProgram"
      id="export-staff"
      ref="export-staff"
      ok-title="Save"
      cancel-variant="outline-secondary"
      centered
      title="Export Staff"
      no-close-on-backdrop
      @ok="saveCSV('staff')"
      @hidden="csvFile = null"
    >
      <div
        v-if="isLoading"
        class="text-center my-1"
      >
        <b-spinner class="align-middle" />
      </div>
      <span v-else>{{ csvName('staff') }}.csv</span>
    </b-modal>

    <bulk-download-files-modal
      v-if="setProgram"
      :program-id="setProgram.id"
      :program-name="setProgram.name"
    />

    <generate-badges-modal
      :program-id="programID"
    />

    <program-application-sharing-modal
      v-if="setProgram"
      :sharing-settings="sharingSettings"
      @saveChanges="saveSharingSettings"
    />
  </div>
</template>

<script>
import moment from 'moment'
import {
  BTabs, BTab, BCard, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'

import { mapGetters } from 'vuex'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { setActiveTab } from '@core/mixins/setActiveTab'
import ProgramTabMain from '@/views/admin/program/program-tabs/ProgramTabMain.vue'
import ProgramTabSessions from '@/views/admin/program/program-tabs/ProgramTabSessions.vue'
import ProgramTabCommunication from '@/views/admin/program/program-tabs/ProgramTabCommunication.vue'
import ProgramTabTasks from '@/views/admin/program/program-tabs/ProgramTabTasks.vue'
import ProgramTabRegistrationStatuses from '@/views/admin/program/program-tabs/ProgramTabRegistrationStatuses.vue'
import ProgramTabFees from '@/views/admin/program/program-tabs/ProgramTabFees.vue'
import ProgramTabWallets from '@/views/admin/program/program-tabs/ProgramTabWallets.vue'
import ProgramTabTags from '@/views/admin/program/program-tabs/ProgramTabTags.vue'

import store from '@/store'
import storeModule from '@/views/admin/program/programStoreModule'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'
import usePaymentContractsSetting from '@/views/admin/payment-contracts/usePaymentContractsSetting'
import BulkDownloadFilesModal from '@/views/admin/program/program-list/BulkDownloadFilesModal.vue'
import GenerateBadgesModal from '@/views/components/generate-badges-modal/GenerateBadgesModal.vue'
import ProgramApplicationSharingModal from '@/views/components/program-application-sharing-modal/ProgramApplicationSharingModal.vue'
import ProgramTabFamilySettings from '@/views/admin/program/program-tabs/ProgramTabFamilySettings.vue'
import ProgramTabCamperInfoTab from '@/views/admin/program/program-tabs/ProgramTabCamperInfoTab.vue'

export default {
  components: {
    ProgramTabFamilySettings,
    ProgramTabMain,
    ProgramTabSessions,
    ProgramTabCommunication,
    ProgramTabRegistrationStatuses,
    ProgramTabTasks,
    ProgramTabFees,
    ProgramTabWallets,
    ProgramTabTags,
    ProgramTabCamperInfoTab,
    BTabs,
    BTab,
    BCard,
    BDropdown,
    BDropdownItem,
    BSpinner,

    BulkDownloadFilesModal,
    GenerateBadgesModal,
    ProgramApplicationSharingModal,
  },
  mixins: [downloadExportFile, setActiveTab],
  data() {
    return {
      tabIndex: 0,
      groupID: null,
      camperFormID: null,
      staffFormID: null,
      setProgram: null,
      sharingSettings: {
        type: 'enable_with_link',
        selectedAccounts: [],
        link: '',
      },
      options: {
        main: {
          avatar: null,
          title: null,
          prefix: null,
          date: null,
          description: null,
          capacity: null,
          gender: null,
          minAge: null,
          maxAge: null,
          costForAllSessions: null,
          virtualProgram: true,
          hidePrice: false,
          gallery: [],
          role: null,
          limitSessionSelection: false,
          complete_registration_btn_text: 'Complete Registration',
        },
        sessions: [],
        paymentPlan: {
          id: null,
          max_months: 0,
          is_enable: false,
        },
        communication: {
          email: null,
          name: null,
        },
        communicationSettingsList: [],
        rolesList: [],
        eventsList: [],
        familySettings: {
          use_family_form: false,
          form_id: null,
          program_id: null,
          is_family_form_required_for_applications: false,
          allow_to_create_multiple_parents: false,
          is_required_second_parent: false,
          is_required_confirmation_family_form: false,
        },
        camperTabInfoSettings: [],
      },
      paymentContract: {
        id: null,
      },
      paymentContractsList: [],
      csvFile: null,
    }
  },
  computed: {
    ...mapGetters({
      programID: 'verticalMenu/getDefaultProgram',
    }),
    isLoading: {
      get() {
        return this.$store.getters['app-program/getIsLoading']
      },
      set(val) {
        this.$store.commit('app-program/SET_IS_LOADING', val)
      },
    },
    hasUnsavedChanges: {
      get() {
        return store.getters['app-program/getHasUnsavedChanges']
      },
      set(val) {
        store.commit('app-program/SET_HAS_UNSAVED_CHANGES', val)
      },
    },
    statementTemplate: {
      get() {
        return store.getters['app-program/getStatementTemplate']
      },
      set(val) {
        store.commit('app-program/SET_STATEMENT_TEMPLATE', val)
      },
    },
  },
  watch: {
    tabIndex(val) {
      this.setTabIndexQuery(val)
    },
    $route() {
      this.setActiveTab()
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      updProgram,
      fetchProgram,
      fetchForms,
      saveCommunicationSetting,
      postPaymentPlan,
      updatePaymentPlan,
      updatePaymentContract,
      cloneProgram,
      deleteProgram,
      exportCampers,
      exportStaff,
      canDeleteProgram,
      canUpdateGeneralProgramSettings,
      canUpdateRegistrationChildProgramSettings,
      canUpdateRegistrationStaffProgramSettings,
      canUpdateProgramTasks,
      canUpdateProgramFees,
      canUpdateProgramWallets,
      canUpdateProgramTags,
    } = useProgramsSetting()

    const {
      fetchPaymentContractList,
    } = usePaymentContractsSetting()

    return {
      updProgram,
      fetchProgram,
      fetchForms,
      saveCommunicationSetting,
      postPaymentPlan,
      updatePaymentPlan,
      fetchPaymentContractList,
      updatePaymentContract,
      cloneProgram,
      deleteProgram,
      exportCampers,
      exportStaff,
      canDeleteProgram,
      canUpdateGeneralProgramSettings,
      canUpdateRegistrationChildProgramSettings,
      canUpdateRegistrationStaffProgramSettings,
      canUpdateProgramTasks,
      canUpdateProgramFees,
      canUpdateProgramWallets,
      canUpdateProgramTags,
    }
  },
  async created() {
    this.isLoading = true
    await this.getRolesAndEventsForCommunicationSettings()
    store.commit('app-program/SET_PROGRAM_ID', this.programID)

    if (this.programID) this.setProgram = await this.fetchProgram(this.programID)

    this.options.main.avatar = this.setProgram?.logo
    this.options.main.logoUploaded = null
    this.options.main.title = this.setProgram?.name
    this.options.main.prefix = this.setProgram?.prefix
    this.options.main.date = `${this.setProgram?.date_start} to ${this.setProgram?.date_end}`
    this.options.main.description = this.setProgram?.description

    this.options.main.capacity = this.setProgram?.capacity
    this.options.main.gender = this.setProgram?.gender
    this.options.main.minAge = this.setProgram?.age_range_from
    this.options.main.maxAge = this.setProgram?.age_range_to
    this.options.main.virtualProgram = this.setProgram?.is_virtual_program
    this.options.main.hidePrice = this.setProgram?.is_hide_price
    this.options.main.enableRegistration = this.setProgram?.is_active
    this.options.main.gallery = this.setProgram?.gallery_url || []
    this.options.main.updatedGallery = this.setProgram?.gallery || []
    this.options.main.role = this.setProgram?.applicable_role
    this.options.main.limitSessionSelection = this.setProgram?.is_only_one_session_selection_allowed
    this.options.main.complete_registration_btn_text = this.setProgram?.complete_registration_btn_text

    this.options.communication.id = this.setProgram?.communication_settings_id
    this.options.communication.email = this.setProgram?.communication_from_email
    this.options.communication.name = this.setProgram?.communication_from_name

    if (this.setProgram?.family_settings) {
      this.options.familySettings = this.setProgram?.family_settings
    }

    if (this.setProgram?.camper_tab_info_settings?.data_settings) {
      this.options.camperTabInfoSettings = this.setProgram?.camper_tab_info_settings?.data_settings
    }

    if (!this.options.familySettings.program_id) {
      this.options.familySettings.program_id = this.programID
    }
    if (this.setProgram?.payment_plan) {
      this.options.paymentPlan = this.setProgram?.payment_plan
    }
    if (this.setProgram?.payment_contract) {
      this.paymentContract.id = this.setProgram?.payment_contract.id
    }
    if (this.setProgram?.tuition_card_template) {
      this.statementTemplate = this.setProgram?.tuition_card_template?.id
    }

    if (this.setProgram.forms) {
      this.camperFormID = this.setProgram.forms.filter(form => form.type === 'Camper')[0]?.id
      this.staffFormID = this.setProgram.forms.filter(form => form.type === 'Staff')[0]?.id
    }

    this.groupID = this.setProgram?.group_id
    this.sharingSettings = this.setProgram.sharing_settings
    this.paymentContractsList = await this.fetchPaymentContractList()
    await this.fetchSessions()
    await this.fetchAllForms()
    await this.fetchStatementTemplates()
    await this.preparedCommunicationSettings()

    this.isLoading = false
  },
  mounted() {
    this.setActiveTab()
  },
  destroyed() {
    store.commit('app-program/SET_PROGRAM_ID', null)
  },
  methods: {
    tabActivated(newTabIndex, oldTabIndex, event) {
      if (this.hasUnsavedChanges) {
        // eslint-disable-next-line no-alert
        if (!window.confirm('There are some changes in the current tab that was not saved. Are you sure you want to leave the tab and discard changes?')) {
          event.preventDefault()
        } else {
          this.hasUnsavedChanges = false
        }
      }
    },
    async fetchAllForms() {
      const response = await this.$store.dispatch('app-program/fetchForms')
      store.commit('app-program/SET_FORMS_LIST', response.data.data)
    },
    async fetchStatementTemplates() {
      const response = await this.$store.dispatch('app-program/fetchStatementTemplates')
      store.commit('app-program/SET_STATEMENT_TEMPLATES_LIST', response.data.data)
    },
    async fetchSessions() {
      if (this.programID) {
        await this.$store.dispatch('app-program/fetchSessions', {
          programId: this.programID,
          sortBy: 'position',
        })
          .then(response => {
            const sessions = response.data.data
            if (sessions) {
              sessions.forEach((session, index) => {
                this.options.sessions.push({
                  id: session.id,
                  frontID: index,
                  name: session.name,
                  date: `${session.start_date} to ${session.end_date}`,
                  price: session.price,
                  registration: !session.allowed_individual_selection,
                  delete: false,
                  divisions: session.division_groups,
                })
              })
            } else {
              this.addEmptySession()
            }
          })
      } else {
        this.addEmptySession()
      }
    },
    async addEmptySession() {
      this.options.sessions = [{
        name: '',
        date: '',
        price: '',
        registration: true,
        delete: false,
        divisions: [],
      }]
    },
    async saveChanges() {
      this.isLoading = true
      await this.updateProgram()
      await this.saveCommunicationsSettings()
      await this.savePaymentPlan()
      await this.savePaymentContract()
      this.isLoading = false
    },
    async updateProgram() {
      await this.sendProgramData()
      this.tabIndex += 1
    },
    async sendProgramData() {
      const [dateStart, , dateEnd] = (this.options.main.date).split(' ')

      const postProgram = {
        id: this.setProgram?.id,
        name: this.options.main.title,
        prefix: this.options.main.prefix,
        logoUploaded: this.options.main?.logoUploaded,
        gallery: this.options.main?.updatedGallery,
        date_start: dateStart,
        date_end: dateEnd === undefined ? dateStart : dateEnd,
        capacity: this.options.main.capacity,
        gender: this.options.main.gender,
        applicable_role: this.options.main.role,
        age_range_from: this.options.main.minAge,
        age_range_to: this.options.main.maxAge,
        is_virtual_program: this.options.main.virtualProgram,
        is_active: this.options.main.enableRegistration,
        is_hide_price: this.options.main.hidePrice,
        is_only_one_session_selection_allowed: this.options.main.limitSessionSelection,
        communication_settings_id: this.options.communication.id,
        communication_settings_from_name: this.options.communication.name,
        communication_settings_from_email: this.options.communication.email,
        complete_registration_btn_text: this.options.main.complete_registration_btn_text,
        description: this.options.main.description,
        sessions: this.options.sessions.map(session => ({
          ...session,
          registration: !session.registration,
        })),
        sharing_settings: this.sharingSettings,
        tuition_card_template: this.statementTemplate,
      }

      this.groupID = await this.updProgram(postProgram)
    },
    async saveSharingSettings(updatedSettings) {
      this.sharingSettings = updatedSettings
      await this.sendProgramData()
    },
    async saveCommunicationsSettings() {
      const formData = this.options.communicationSettingsList
        .map(settings => ({
          role_id: settings.role.id,
          email_template_id: settings.emailTemplate?.id,
          user_event_id: settings.userEvent.id,
          program_id: this.setProgram.id,
        }))
      this.saveCommunicationSetting(formData)
    },
    async savePaymentPlan() {
      this.options.paymentPlan.program_id = this.setProgram.id

      if (this.options.paymentPlan.id) {
        this.updatePaymentPlan(this.options.paymentPlan)
      } else {
        this.postPaymentPlan(this.options.paymentPlan)
      }
    },
    async savePaymentContract() {
      const queryParams = {
        programId: this.programID,
        paymentContractId: this.paymentContract.id || '',
      }
      await this.updatePaymentContract(queryParams)
    },
    async createdCamperForm(formId) {
      this.camperFormID = formId
    },
    async createdStaffForm(formId) {
      this.staffFormID = formId
    },
    async getRolesAndEventsForCommunicationSettings() {
      this.rolesList = await this.$store.dispatch('app-program/fetchRolesList')
        .then(response => response.data.data.filter(item => item.name === 'staff' || item.name === 'parent'))

      this.eventsList = await this.$store.dispatch('app-program/fetchEventsList', { type: 'program' })
        .then(response => response.data.data)
    },
    async preparedCommunicationSettings() {
      const allPreparedSettings = []
      this.eventsList.forEach(event => {
        this.rolesList.forEach(role => {
          allPreparedSettings.push({ role, userEvent: event, emailTemplate: { id: null } })
        })
      })
      if (this.programID) {
        const chosenSettings = await this.$store
          .dispatch('app-program/fetchCommunicationSettingsList', { program_id: this.programID })
          .then(response => response.data.data)

        chosenSettings.forEach(chosenSetting => {
          allPreparedSettings.forEach((setting, index) => {
            if (setting.userEvent.id === chosenSetting.userEvent.id && setting.role.id === chosenSetting.role.id) {
              allPreparedSettings[index] = chosenSetting
            }
          })
        })
      }

      this.options.communicationSettingsList = allPreparedSettings
    },
    navigateToLink(link, data) {
      if (link === 'admin-report-details') {
        this.$router.push({ name: link, query: { program_id: data.item.id, report_view_name: 'Application Report View by Program ID' } })
      } else {
        this.$router.push({ name: link, params: { id: data.item.id, name: data.item.name, type: 'Camper' } })
      }
    },
    csvName(type) {
      return `${this.setProgram.name}-${type}-${moment().format('YYYY-MM-DD')}`
    },
    async expCampers() {
      this.isLoading = true

      const formData = {
        programId: this.programID,
      }

      this.$refs['export-campers'].show()

      await this.exportCampers(formData)
        .then(response => {
          if (response.status === 200) {
            this.csvFile = response.data
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async expStaff() {
      this.isLoading = true

      const formData = {
        programId: this.programID,
      }

      this.$refs['export-staff'].show()
      await this.exportStaff(formData)
        .then(response => {
          if (response.status === 200) {
            this.csvFile = response.data
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    saveCSV(type) {
      this.downloadFile(this.csvFile, this.csvName(type), 'csv')
    },
    async cloneAndSelectProgram(programID) {
      try {
        const res = await this.cloneProgram(programID)

        const clonedProgramId = res.id

        await this.$store.dispatch('verticalMenu/fetchProgramOptions')
        this.$store.commit('verticalMenu/UPDATE_DEFAULT_PROGRAM', clonedProgramId)
        this.$router.push({ name: 'admin-program-edit', params: { id: clonedProgramId }, query: { tab_index: 0 } })
        return res
      } catch (error) {
        return error
      }
    },
    async deleteAndSelectDefaultProgram(programID) {
      try {
        const res = await this.deleteProgram(programID)
        const programsList = await this.$store.dispatch('verticalMenu/fetchProgramOptions')
        const defaultProgram = programsList.data.data[0]?.id || ''
        this.$store.commit('verticalMenu/UPDATE_DEFAULT_PROGRAM', defaultProgram)
        if (defaultProgram) {
          this.$router.push({ name: 'admin-program-edit', params: { id: defaultProgram }, query: { tab_index: 0 } })
        } else {
          this.$router.push({ name: 'admin-dashboard' })
        }
        return res
      } catch (error) {
        return error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-height {
  height: 60px;
}
</style>
