<template>
  <div>
    <spinner
      v-if="isLoading"
      label="Loading"
      small
    />
    <b-card v-else>
      <validation-observer
        ref="camperBlocks"
        tag="form"
      >
        <b-form>
          <b-button
            variant="outline-primary"
            @click.prevent="addBlock()"
          >
            Add Block
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
          </b-button>

          <draggable
            :list="blocks"
            :group="{name: 'blocks'}"
            class="mb-1 cursor-move border-2 border-top-3"
          >
            <b-card
              v-for="block in blocks"
              :key="'block'+block.id"
              no-body
              class="block-card"
            >

              <b-card-header
                class="d-flex align-items-center justify-content-between"
              >
                <b-card-title
                  class="d-flex align-items-center justify-content-between"
                >
                  <div
                    v-if="!editBockMode || manageableBlockId !== block.id"
                    class="d-flex align-items-center text-break"
                  >
                    <span>{{ block.name }}</span>
                  </div>

                  <div
                    v-if="editBockMode && manageableBlockId === block.id"
                    class="d-flex align-items-center"
                  >
                    <b-form-input
                      v-model="manageableBlockName"
                      :value="manageableBlockName"
                      size="sm"
                    />
                  </div>

                  <div
                    v-if="editBockMode && manageableBlockId === block.id"
                  >
                    <span
                      class="text-success cursor-pointer"
                      @click="saveBlockName"
                    >
                      <feather-icon
                        size="16"
                        icon="CheckIcon"
                        class="ml-1"
                      /> Apply
                    </span>

                    <span
                      class="text-danger cursor-pointer"
                      @click="clearBlockData"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="ml-1"
                      /> Cancel
                    </span>
                  </div>

                  <span
                    v-else
                    class="text-primary cursor-pointer"
                    @click="editBlockName(block)"
                  >
                    <feather-icon
                      size="21"
                      icon="EditIcon"
                      class="cursor-pointer text-primary ml-1"
                    />
                  </span>
                </b-card-title>
                <div
                  class="d-flex align-items-center justify-content-between"
                >
                  <b-button
                    variant="outline-primary"
                    class="mr-1"
                    @click.prevent="addItem(block.id)"
                  >
                    New Field
                  </b-button>

                  <SquareIcon
                    size="21"
                    icon="Trash2Icon"
                    class="cursor-pointer"
                    @onIconClick="removeBlock(block.id)"
                  />
                </div>
              </b-card-header>

              <b-card-body>
                <draggable
                  :list="block.items"
                  :group="{name: 'items'}"
                  class="mb-1 cursor-move border-2 border-top-3"
                >
                  <b-row
                    v-for="(item, index) in block.items"
                    :key="'item'+item.id"
                    :class="editItemMode && manageableItemId === item.id ? 'edit-item' : ''"
                  >
                    <b-col
                      cols="1"
                      class="d-flex justify-content-end align-items-center"
                    >
                      <img
                        src="@/assets/images/icons/more-vertical-v2.svg"
                        class="handle-drag cursor-move mr-1"
                      >
                    </b-col>

                    <b-col
                      cols="5"
                      class="fields-item"
                    >
                      <b-form-group
                        label="Label"
                        :label-for="'field-label-' + index + item.id + item.label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="label"
                          rules="required"
                        >
                          <b-form-input
                            :id="'field-label-' + index + item.id + item.label"
                            v-model="item.label"
                            :state="errors.length > 0 ? false:null"
                            :disabled="!(editItemMode && manageableItemId === item.id)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="4"
                      class="fields-item"
                    >
                      <b-form-group
                        label="Field"
                        :label-for="'field-type-' + index + item.id + block.id"
                      >
                        <b-form-input
                          :id="'field-type-' + index + item.id + block.id"
                          v-model="item.field_type"
                          :disabled="true"
                        />

                        <available-placeholder-with-preloaded-data
                          v-if="editItemMode && manageableItemId === item.id"
                          :id="'field-type-' + index + item.id + block.id"
                          class="mt-2"
                          :prepared-unique-fields="uniqueFields"
                          :prepared-placeholders="placeholders"
                          :prepared-family-attributes="uniqueFamilyAttributes"
                          :prepared-parents-attributes="uniqueParentsAttributes"
                          :prepared-form-fields="formFields"
                          :prepared-wallets="wallets"
                          :prepared-group-types="groupTypesForSelect"
                          @insertValueToEditor="setItemFieldName"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="1"
                      class="d-flex justify-content-end align-items-center"
                    >
                      <span
                        v-if="editItemMode && manageableItemId === item.id"
                        class="text-success cursor-pointer"
                        @click="saveItem"
                      >
                        <feather-icon
                          size="21"
                          icon="CheckIcon"
                          class="ml-1"
                        /> Apply
                      </span>

                      <span
                        v-else
                        class="text-primary cursor-pointer"
                        @click="editItem(block.id, item.id)"
                      >
                        <feather-icon
                          size="21"
                          icon="EditIcon"
                          class="cursor-pointer text-primary ml-1"
                        />
                      </span>

                    </b-col>
                    <b-col
                      cols="1"
                      class="d-flex justify-content-end align-items-center"
                    >
                      <SquareIcon
                        size="21"
                        icon="Trash2Icon"
                        class="cursor-pointer"
                        @onIconClick="removeItem(block.id, item.id)"
                      />
                    </b-col>
                  </b-row>
                </draggable>
              </b-card-body>

              <hr class="w-100 my-2">
            </b-card>

          </draggable>

          <!-- buttons -->
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mt-2 mr-1"
                :disabled="isLoading"
                @click="handleForm"
              >
                Save changes
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                :to="{ name: 'admin-program'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import AvailablePlaceholderWithPreloadedData
  from '@/views/components/available-placeholder/AvailablePlaceholderWithPreloadedData.vue'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import useProgramCamperInfoTabSetting from '@/views/admin/program/useProgramCamperInfoTabSetting'

export default {
  name: 'ProgramTabCamperInfoTab',
  components: {
    Spinner,
    AvailablePlaceholderWithPreloadedData,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BForm,
    BButton,

    draggable,
    SquareIcon,
  },
  props: {
    camperTabInfoSettings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      isSetData: false,
      editBockMode: false,
      editItemMode: false,
      manageableBlockId: null,
      manageableItemId: null,
      manageableBlockName: null,
      blocks: [
        // {
        //   id: 1,
        //   name: 'Camper Information',
        //   items: [
        //     {
        //       id: 1,
        //       label: 'First Name',
        //       field_type: 'first_name',
        //     },
        //     {
        //       id: 2,
        //       label: 'Last Name',
        //       field_type: 'last_name',
        //     },
        //   ],
        // },
        // {
        //   id: 2,
        //   name: 'Address',
        //   items: [
        //     {
        //       id: 3,
        //       label: 'Address Line 1',
        //       field_type: 'address_line_1',
        //     },
        //     {
        //       id: 4,
        //       label: 'Address Line 2',
        //       field_type: 'address_line_2',
        //     },
        //     {
        //       id: 5,
        //       label: 'City',
        //       field_type: 'city',
        //     },
        //   ],
        // },
      ],
    }
  },
  computed: {
    ...mapGetters({
      programId: 'app-program/getProgramId',
      uniqueFields: 'app-program/getUniqueFields',
      placeholders: 'app-program/getPlaceholders',
      uniqueFamilyAttributes: 'app-program/getUniqueFamilyAttributes',
      uniqueParentsAttributes: 'app-program/getUniqueParentsAttributes',
      formFields: 'app-program/getFormFields',
      wallets: 'app-program/getWallets',
      groupTypesForSelect: 'app-program/getGroupTypesForSelect',
    }),
  },
  watch: {
    async programId() {
      await this.setData()
    },
  },
  setup() {
    const {
      saveCamperTabSettings,
    } = useProgramCamperInfoTabSetting()

    return {
      saveCamperTabSettings,
    }
  },
  async mounted() {
    this.isSetData = false
    this.blocks = this.camperTabInfoSettings
    await this.setData()
  },
  async beforeDestroy() {
    this.isSetData = false
  },
  methods: {
    async setData() {
      this.isLoading = true

      if (this.programId) {
        await this.$store.dispatch('app-program/fetchAvailablePlaceholders')
      }

      this.isSetData = true
      this.isLoading = false
    },
    removeItem(blockId, itemId) {
      const block = this.blocks.find(b => b.id === blockId)
      if (block) {
        block.items = block.items.filter(item => item.id !== itemId)
      }
    },
    addItem(blockId) {
      const itemId = uuidv4()
      const block = this.blocks.find(b => b.id === blockId)
      if (block) {
        block.items.push({
          id: itemId,
          label: '',
          field_type: '',
        })
      }

      this.editItem(blockId, itemId)
    },
    addBlock() {
      const itemId = uuidv4()
      const newBlock = {
        id: uuidv4(),
        name: 'New Block',
        items: [
          {
            id: itemId,
            label: '',
            field_type: '',
          },
        ],
      }

      this.blocks.push(newBlock)

      this.editItem(newBlock.id, itemId)
    },
    removeBlock(blockId) {
      this.blocks = this.blocks.filter(b => b.id !== blockId)
    },
    editBlockName(block) {
      this.manageableBlockId = block.id
      this.manageableBlockName = block.name
      this.editBockMode = true
    },
    saveBlockName() {
      const block = this.blocks.find(b => b.id === this.manageableBlockId)
      block.name = this.manageableBlockName
      this.clearBlockData()
    },
    async clearBlockData() {
      this.manageableBlockId = null
      this.manageableBlockName = null
      this.editBockMode = false
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.camperBlocks.validate().then(success => {
          if (success) {
            this.saveCamperTabSettings({
              program_id: this.programId,
              data_settings: this.blocks,
            })
          } else {
            reject()
          }
        })
      })
    },
    editItem(blockId, itemId) {
      this.manageableBlockId = blockId
      this.manageableItemId = itemId
      this.editItemMode = true
    },
    setItemFieldName(value) {
      const block = this.blocks.find(b => b.id === this.manageableBlockId)
      if (block) {
        const item = block.items.find(item => item.id === this.manageableItemId)
        item.field_type = value
      }
    },
    saveItem() {
      this.manageableBlockId = null
      this.manageableItemId = null
      this.editItemMode = false
    },
  },
}
</script>

<style lang="scss">
hr {
  border-top-width: 2px;
}

.edit-item {
 background-color: rgba(#3B4253FF, 0.12);
}

</style>
